<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-sm-flex">
      <dark-toggler class="d-none d-sm-block" />
      <search-bar
        class="d-block"
        placeholder="Explore XML TV Host"
        v-show="true"
      />
      <a
        href="javascript:void(0)"
        class="nav-link nav-link-search d-none d-sm-block"
        @click="openOnboarding"
      >
        <feather-icon icon="InfoIcon" size="21" />
      </a>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        v-if="userData"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user h-auto"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.email }}
            </p>
            <span v-if="isAdmin" class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :badge-variant="userData.services.length > 0 ? 'success' : 'dark'"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="navigateToProfile"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-divider class="d-sm-none d-block" />
        <b-dropdown-item
          @click="onToggleTheme"
          link-class="align-items-center d-sm-none d-flex"
        >
          <feather-icon
            :icon="`${isThemeDark ? 'Sun' : 'Moon'}Icon`"
            size="16"
            class="mr-50"
          ></feather-icon>
          <div>{{ isThemeDark ? "Light mode" : "Dark mode" }}</div>
        </b-dropdown-item>
        <b-dropdown-divider class="d-sm-none d-block" />
        <b-dropdown-item class="d-sm-none d-block">
          <a
            href="javascript:void(0)"
            @click="openOnboarding"
            class="d-flex align-items-center light-text dark-text"
          >
            <feather-icon icon="InfoIcon" size="16" class="mr-50" />
            Open onboarding
          </a>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <router-link
      v-if="!userData"
      to="/registration"
      class="px-1 cursor-pointer d-none d-sm-block"
    >
      Create account
    </router-link>
    <router-link v-if="!userData" to="/login" class="px-1 cursor-pointer">
      Login
      <feather-icon size="21" icon="LogInIcon" />
    </router-link>
    <b-navbar-nav class="nav align-items-center">
      <b-nav-item-dropdown
        v-if="!userData"
        right
        toggle-class="align-items-center dropdown-user-link d-sm-none d-flex"
        class="dropdown-user"
      >
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="21" />
        </template>
        <b-dropdown-item class="align-middle">
          <router-link to="/registration" class="d-flex align-items-center">
            <feather-icon
              icon="UserPlusIcon"
              size="16"
              class="mr-50"
            ></feather-icon>
            Create account
          </router-link>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          @click="onToggleTheme"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            :icon="`${isThemeDark ? 'Sun' : 'Moon'}Icon`"
            size="16"
            class="mr-50"
          ></feather-icon>
          <div>Toggle color theme</div>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item>
          <a
            href="javascript:void(0)"
            @click="openOnboarding"
            class="d-flex align-items-center light-text dark-text"
          >
            <feather-icon icon="InfoIcon" size="16" class="mr-50" />
            Open onboarding
          </a>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BAvatar,
  BDropdownDivider,
  BDropdownItem,
  BLink,
  BNavbarNav,
  BNavItemDropdown,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    SearchBar,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    FeatherIcon,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isThemeDark() {
      const { skin } = useAppConfig();
      return skin.value === "dark";
    },
    userData: (state) => state.$store.getters["auth/authUser"],
    isAdmin: (state) => state.$store.getters["auth/isAdmin"],
  },
  methods: {
    onToggleTheme() {
      const { skin } = useAppConfig();
      skin.value === "light"
        ? this.$store.commit("appConfig/UPDATE_SKIN", "dark")
        : this.$store.commit("appConfig/UPDATE_SKIN", "light");
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    navigateToProfile() {
      this.$router.push({ name: "profile" });
    },
    openOnboarding() {
      this.$store.commit("appConfig/UPDATE_HIDE_ONBOARDING", false);
    },
  },
};
</script>
<style>
.dark-layout .light-text {
  color: #b4b7bd;
}
.dark-text {
  color: #6e6b7b;
}
.header-navbar .navbar-container .show .dropdown-menu {
  max-height: 300px !important;
}
</style>
