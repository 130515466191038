export default [
  {
    title: "Channels",
    route: { name: "channels" },
    icon: "TvIcon",
  },
  {
    title: "Prices",
    route: "prices",
    icon: "DollarSignIcon",
  },
  {
    header: "EPG",
    onlyAdmin: true,
  },
  {
    title: "Country list",
    route: "countries",
    icon: "ListIcon",
    onlyAdmin: true,
  },
  {
    title: "Channel list",
    route: "channel-list",
    icon: "ListIcon",
    onlyAdmin: true,
  },
  {
    header: "User/Ip",
    onlyAdmin: true,
  },
  {
    title: "Users",
    route: "users",
    icon: "UsersIcon",
    onlyAdmin: true,
  },
  {
    title: "IPs",
    route: "ips",
    icon: "XCircleIcon",
    onlyAdmin: true,
  },
  {
    header: "Payments",
    onlyAdmin: true,
  },
  {
    title: "Payment list",
    route: "payments",
    icon: "DollarSignIcon",
    onlyAdmin: true,
  },
  {
    title: "Subscription list",
    route: "subscription-list",
    icon: "ShoppingBagIcon",
    onlyAdmin: true,
  },
  {
    title: "Orders",
    route: "orders",
    icon: "ShoppingCartIcon",
    onlyAdmin: true,
  },
  {
    header: "Info",
  },
  {
    title: "Privacy",
    route: "privacy",
    icon: "ShieldIcon",
  },
  {
    title: "Terms and conditions",
    route: "terms-and-conditions",
    icon: "FileTextIcon",
  },
  {
    title: "FAQ list",
    route: "faq-list",
    icon: "MessageSquareIcon",
  },
  {
    title: "System config",
    route: "system-config",
    icon: "ToolIcon",
    onlyAdmin: true,
  },
  {
    title: "Email templates",
    route: "email-templates",
    icon: "MailIcon",
    onlyAdmin: true,
  },
  {
    header: "Authentication",
    onlyAdmin: true,
  },
  {
    title: "Edit login",
    route: "login",
    icon: "UserIcon",
    onlyAdmin: true,
  },
  {
    title: "Edit registration",
    route: "registration",
    icon: "UserPlusIcon",
    onlyAdmin: true,
  },
];
