import {computed, ref, watch} from '@vue/composition-api'
import store from "@/store";

export default function useAutoSuggest(props) {
  const filteredData = ref({})

  const authenticatedUser = computed({
    get: () => store.state.auth.user,
  })

  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */
  const filterGrp = (grp, query) => {
    const isAuth = !!authenticatedUser.value;
    const isAdmin = !!authenticatedUser.value?.is_admin;

    const data = grp.data.filter(item=>{
      if(item.onlyAuth){
        return !!isAuth
      }
      if(item.onlyAdmin){
        return isAdmin
      }
      return true
    });

    const exactEle = data.filter(item => item[grp.key].toLowerCase().startsWith(query.toLowerCase()))
    const containEle = data.filter(
      // prettier-ignore
      item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return exactEle.concat(containEle).slice(0, props.searchLimit)
  }

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearchQueryUpdate = val => {
    if (val === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      const dataGrps = Object.keys(props.data)

      dataGrps.forEach((grp, i) => {
        queriedData[dataGrps[i]] = filterGrp(props.data[grp], val)
      })

      filteredData.value = queriedData
    }
  }

  watch(searchQuery, val => handleSearchQueryUpdate(val))

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
  }
}
