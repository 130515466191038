import menuItems from"@/navigation/vertical"

/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      {
        title: "EPG XML Link - Only subscribed users",
        route: "/",
        icon: "TvIcon",
        onlyAuth:true
      },
      {
        title: "Password reset",
        route: "/profile",
        icon: "KeyIcon",
        onlyAuth:true
      },
        ...menuItems.filter((item)=>item?.title)
    ],
  },
  /*
  contacts: {
    key: 'name',
    data: [],
  },
  */
}
/* eslint-enable */
